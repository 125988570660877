import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@src/environments/environment';

if (environment.production) {
  enableProdMode();
}

function removeLoader() {
  const loader = document.getElementById('app-loader');
  if (loader) {
    loader.style.transition = 'opacity 0.4s ease';
    loader.style.opacity = '0';
    setTimeout(() => loader.remove(), 250); // Smooth removal after fade-out
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => removeLoader())
  .catch((err) => console.error(err));
