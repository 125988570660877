export const environment = {
  production: false,
  googleApiKey: 'AIzaSyCrTVnvt996MFPXLS-HEA6r1JZCbNLe5UM',
  api: {
    serverUrl: 'https://api-develop.provider.guestgenie.com/api/v1',
  },
  firebase: {
    apiKey: 'AIzaSyC9fgPCtMPezUBNjKUAIcyEGoCI8q0mhS0',
    authDomain: 'guestgenie-backoffice-develop.firebaseapp.com',
    projectId: 'guestgenie-backoffice-develop',
    storageBucket: 'guestgenie-backoffice-develop.appspot.com',
    messagingSenderId: '2058910583',
    appId: '1:2058910583:web:0f5f422d8d6059b0e6ff72',
  },
  stripeKey: 'pk_test_51PBGEl2LuUb40Y0z2rNQEknV4ZOmezcHc4uX6pqfiPA96131gPlLpSqAtyoNdRcgyC4yMfo4BFmtGyecSVgSA7qf00Dgt5HNtq',
  intercomWorkspace: 'kh5l45bz',
};
