import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import {
  addEntities,
  getActiveEntity,
  getAllEntities,
  resetActiveId,
  selectActiveEntity,
  selectAllEntities,
  selectEntitiesCount,
  setActiveId,
  setEntities,
  updateEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { ProviderModel } from '@src/app/core';

interface ProviderProps {
  isLoading: boolean;
  error: any;
}

const store = createStore(
  { name: 'organizations' },
  withProps<ProviderProps>({ isLoading: false, error: null }),
  withEntities<ProviderModel, '_id'>({ idKey: '_id' }),
  withActiveId(),
);

@Injectable({ providedIn: 'root' })
export class ProviderRepository {
  isLoading$ = store.pipe(select((state) => state.isLoading));
  error$ = store.pipe(select((state) => state.error));

  currentProvider$ = store.pipe(selectActiveEntity());

  providers$ = store.pipe(selectAllEntities());

  providersCount$ = store.pipe(selectEntitiesCount());

  setProviders(organizations: ProviderModel[]) {
    store.update(setEntities(organizations));
  }

  setCurrentProvider(organizationId?: string) {
    if (organizationId) {
      store.update(setActiveId(organizationId || null));
    } else {
      store.update(resetActiveId());
    }
  }

  resetCurrentProvider() {
    store.update(resetActiveId());
  }

  resetEntities() {
    store.update(setEntities([]));
  }

  addProvider(organization: any): void {
    store.update(addEntities(organization));
  }

  updateProvider(organization: any) {
    store.update(updateEntities(organization._id, organization));
  }

  setLoading(loading: boolean) {
    store.update((store) => {
      return { ...store, loading };
    });
  }

  setError(error: any) {
    store.update((store) => {
      return { ...store, error };
    });
  }

  get currentProvider(): ProviderModel | undefined {
    return store.query(getActiveEntity());
  }

  get providers(): ProviderModel[] {
    return store.query(getAllEntities());
  }
}
